<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ formTitle }}</span>
    </v-card-title>
    <v-container fluid>
      <v-form
        ref="form"
        v-model="formValid"
        lazy-validation
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="Name"
              :append-icon="icons.mdiAccountBoxOutline "
              :rules="[Validaciones.requerido]"
              label="Nombre"
              dense
              required
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="SurName"
              :rules="[Validaciones.requerido]"
              label="Apellido"
              dense
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="Street"
              :append-icon="icons.mdiHomeOutline "
              :rules="[Validaciones.requerido]"
              required
              label="Calle y/o Número"
              dense
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="4"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="Floor"
                  :append-icon="'mdi-briefcase'"
                  label="Piso"
                  dense
                  hide-details="true"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="Dpto"
                  :append-icon="'mdi-briefcase'"
                  label="Dpto."
                  dense
                  hide-details="true"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="Mza"
                  type="text"
                  :append-icon="'mdi-briefcase'"
                  label="Mza"
                  dense
                  hide-details="true"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="Lote"
                  type="text"
                  :append-icon="'mdi-briefcase'"
                  label="Lote."
                  hide-details="true"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="City"
              :append-icon="icons.mdiCityVariantOutline "
              :rules="[Validaciones.requerido]"
              label="Ciudad"
              dense
              required
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="Neighborhood"
              :append-icon="icons.mdiHomeGroup"
              :rules="[Validaciones.requerido]"
              name="input-10-1"
              label="Barrio"
              dense
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="Phone"
              :append-icon="icons.mdiCellphone "
              :rules="[Validaciones.requerido, Validaciones.telefono]"
              required
              label="Teléfono"
              dense
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="Email"
              :append-icon="icons.mdiAt"
              :rules="[Validaciones.emailRules]"
              label="Correo Electrónico"
              dense
              required
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="Dni"
              :append-icon="icons.mdiCardAccountDetailsOutline "
              :rules="[Validaciones.requerido, Validaciones.dni]"
              label="D.N.I."
              dense
              required
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="SexoSeleccionado"
              :items="Sex"
              item-value="idSex"
              item-text="descripcionSexo"
              :append-icon="icons.mdiHumanMaleFemale "
              label="Sexo"
              dense
              :rules="[Validaciones.requerido]"
              outlined
              hide-details="true"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="RolSeleccionado"
              :items="Rol"
              item-value="idRol"
              item-text="descripcionRol"
              :append-icon="icons.mdiShieldAccountOutline"
              label="Rol"
              :readonly="!isGaston"
              :rules="[Validaciones.requerido]"
              dense
              outlined
              hide-details="true"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          justify="start"
        >
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="completeUserName"
              :append-icon="icons.mdiAccount "
              :rules="[Validaciones.requerido]"
              required
              label="Nombre Usuario Login"
              dense
              readonly
              hide-details="true"
              outlined
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-if="guardaEmpleadoNuevo"
              v-model="password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
              :rules="[Validaciones.requerido, Validaciones.minPassword]"
              :type="isPasswordVisible ? 'text' : 'password'"
              name="input-10-1"
              label="Contraseña"
              hint="Minimo 6 Caracteres"
              counter
              hide-details="true"
              dense
              outlined
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
            <v-text-field
              v-else
              v-model="password"
              :append-icon="isPasswordVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
              :rules="[Validaciones.minPassword]"
              :type="isPasswordVisible ? 'text' : 'password'"
              name="input-10-1"
              label="Contraseña (En blanco para no actualizar)"
              hint="Minimo 6 Caracteres"
              counter
              hide-details="true"
              dense
              outlined
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
          <v-text-field
              v-if="!guardaEmpleadoNuevo && password"
              v-model="passwordConfirm"
              :append-icon="isPasswordConfirmVisible ? icons.mdiEyeOutline : icons.mdiEyeOffOutline"
              :rules="[Validaciones.requerido, Validaciones.minPassword]"
              :type="isPasswordConfirmVisible ? 'text' : 'password'"
              name="input-10-1"
              label="Confirmar Contraseña"
              hint="Minimo 6 Caracteres"
              counter
              hide-details="true"
              dense
              outlined
              @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
            ></v-text-field>
        </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        depressed
        @click="close()"
      >
        Cancelar
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn
        v-if="guardaEmpleadoNuevo"
        color="primary"
        :loading="isSaving"
        depressed
        @click="InsertEmployee()"
      >
        Guardar
      </v-btn>
      <v-btn
        v-else
        color="primary"
        :loading="isSaving"
        depressed
        @click="ActualizarEmpleado()"
      >
        Actualizar
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-snackbar
      v-model="Showsnackbar"
      top
      :color="colorSnack"
    >
      {{ mensajeSnack }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          :loading="loadingButton"
          @click="Showsnackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>
<script>
import axios from 'axios'
import sha512 from 'js-sha512'
import {
  mdiEyeOutline, mdiCityVariantOutline, mdiShieldAccountOutline, mdiEyeOffOutline,
  mdiAccount, mdiCakeVariantOutline, mdiHomeOutline, mdiHomeGroup,
  mdiCardAccountDetailsOutline, mdiCellphone, mdiAt, mdiAccountBoxOutline, mdiHumanMaleFemale,
} from '@mdi/js'

export default {
  data() {
    return {
      guardaEmpleadoNuevo: true,
      icons: {
        mdiCityVariantOutline,
        mdiShieldAccountOutline,
        mdiHomeOutline,
        mdiHumanMaleFemale,
        mdiHomeGroup,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiCardAccountDetailsOutline,
        mdiCellphone,
        mdiAt,
        mdiAccountBoxOutline,
        mdiCakeVariantOutline,
        mdiAccount,

      },
      isGaston: false,
      loadingButton: false,
      mensajeSnack: '',
      Showsnackbar: false,
      colorSnack: 'success',
      isPasswordVisible: '',
      isPasswordConfirmVisible: '',
      passwordConfirm: '',
      Rol: [{
        idRol: 1,
        descripcionRol: 'Gerente',
      },
      {
        idRol: 2,
        descripcionRol: 'Administrador',
      },
      {
        idRol: 3,
        descripcionRol: 'Encargado',
      },
      {
        idRol: 4,
        descripcionRol: 'Cobrador',
      },
      ],
      Sex: [{
        idSex: 'F',
        descripcionSexo: 'Femenino',
      },
      {
        idSex: 'M',
        descripcionSexo: 'Masculino',
      },
      ],
      SexoSeleccionado: null,
      RolSeleccionado: 4,
      activePicker: null,
      date: null,
      Name: '',
      SurName: '',
      Street: '',
      Floor: null,
      Dpto: null,
      Mza: '',
      Lote: '',
      City: '',
      Neighborhood: '',
      Phone: '',
      Email: '',
      Dni: '',
      UserName: '',
      password: '',
      AccountId: null,
      menu: false,
      dialog: false,
      formValid: null,
      isSaving: false,
      contratistaSeleccionado: null,
      tipoModal: 0,
      firstUserName: '',
      lastUserName: '',
      Validaciones: {
        emailRules:
        v => {
          if (v.length > 0) {
            return /.+@+.+.com+/.test(v) ? true : 'E-mail debe ser valido'
          }

          return true
        },
        requerido: v => !!v || 'Campo Requerido',
        minPassword: v => {
          if (this.guardaEmpleadoNuevo) {
            return v.length >= 6 ? true : 'Minimo 6 caracteres'
          }
          if (v.length >= 1) {
            return v.length >= 6 ? true : 'Minimo 6 caracteres'
          }

          return true
        },
        dni: v => (v.length >= 7 && v.length <= 8) || 'DNI debe ser 7 u 8 digitos',
        telefono: v => (/(3)\d{9}/.test(v) && v.length === 10 ? true : 'Celular debe ser de 10 digitos sin 0 ni 15'),
      },
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.id === 0
        ? 'Registrar Empleado'
        : `Editar Empelado Nro.: ${this.$route.params.id}`
    },
    completeUserName() {
      return this.firstUserName + this.lastUserName
    },
  },
  watch: {
    Name() {
      this.firstUserName = ''
      const arrayName = this.Name.split(' ')
      arrayName.forEach(name => {
        if (name.length > 0) this.firstUserName += name[0]
      })
    },
    SurName() {
      if (this.SurName.indexOf(' ') >= 0) this.lastUserName = this.SurName.split(' ')[0]
      else this.lastUserName = this.SurName

      // this.lastUserName = this.SurName.slice(' ', 20)
    },
    menu(val) {
      // eslint-disable-next-line no-unused-expressions, no-return-assign
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getEmployeebyID(this.$route.params.id)
      this.guardaEmpleadoNuevo = false
    }
    this.checkIfCanChangeEmployeeRole()
  },
  methods: {
    checkIfCanChangeEmployeeRole() {
      var user = this.$store.getters.usuario
      if (user.user_name === "grmachado"){
        this.isGaston = true
      }
    },
    close() {
      this.$router.push('/employee')
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    submitForm() {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('Recargar')
    },

    orderList() {
      this.list = this.list.sort((one, two) => one.order - two.order)
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element

      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      )
    },

    async getEmployeebyID(personId) {
      this.url = `${this.$store.getters.urlBase}/api/Employee/employeeById`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { idPerson: personId },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)

      if (respuesta.status === 200) {
        this.SexoSeleccionado = respuesta.data.sex.trim()
        this.AccountId = respuesta.data.userAccountID
        this.Name = respuesta.data.name
        this.SurName = respuesta.data.surname
        this.Street = respuesta.data.street
        this.Floor = respuesta.data.floor
        this.Dpto = respuesta.data.dpto
        this.Mza = respuesta.data.mza
        this.Lote = respuesta.data.lote
        this.RolSeleccionado = this.Rol.filter(x => x.descripcionRol === respuesta.data.rol)[0].idRol
        this.City = respuesta.data.city
        this.Neighborhood = respuesta.data.neighborhood
        this.Phone = respuesta.data.phone.toString()
        this.Email = respuesta.data.email
        this.Dni = respuesta.data.dni.toString()
      } else {
        // this.colorSnack = "red";
        // this.mensajeSnack = respuesta.data;
        // this.Showsnackbar = true;

      }
    },
    async InsertEmployee() {
      if (this.$refs.form.validate()) {
        this.url = `${this.$store.getters.urlBase}/api/Employee`

        const params = {}
        params.account = {

          rolId: this.RolSeleccionado,
          userName: this.completeUserName,
          password: sha512(this.password),
        }
        params.person = {
          loggedPersonId: this.$store.getters.usuario.user_personid,
          dni: this.Dni,
          name: this.Name,
          surName: this.SurName,
          sex: this.SexoSeleccionado,
          dateBorn: this.date,
          phone: this.Phone,
          email: this.Email,
          address: {
            street: this.Street,
            city: this.City,
            neighborhood: this.Neighborhood,
            floor: this.Floor,
            dpto: this.Dpto,
            mza: this.Mza,
            lote: this.Lote,
            // positionX: 0,
            // positionY: 0,
          },
        }

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
              if (this.respuesta.status === 200) {
                this.$store.dispatch('showSnack', {
                  text: 'Creacion exitosa',
                  color: 'success',
                  timeout: 2500,
                })
                this.$router.push({ name: 'employee' })
              }
            },
          )
          .catch(e => {
            if (e.response) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,
            })
            this.isSaving = false
          })
      }
    },
    async ActualizarEmpleado() {
      if (this.$refs.form.validate()) {
        this.url = `${this.$store.getters.urlBase}/api/Employee/update`
        const params = {}
        params.account = {
          accountId: parseInt(this.AccountId),
          rolId: this.RolSeleccionado,
          userName: this.completeUserName,
          password: this.password ? sha512(this.password) : null,
        }
        params.person = {
          loggedPersonId: this.$store.getters.usuario.user_personid,
          personId: this.$route.params.id,
          dni: this.Dni,
          name: this.Name,
          surName: this.SurName,
          sex: this.SexoSeleccionado,
          dateBorn: this.date,
          phone: this.Phone,
          email: this.Email,
          address: {
            street: this.Street,
            city: this.City,
            neighborhood: this.Neighborhood,
            floor: this.Floor,
            dpto: this.Dpto,
            mza: this.Mza,
            lote: this.Lote,
          },
        }
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.usuario.token.token}`,
            'content-type': 'application/json',
          },
        }
        await axios
          .post(this.url, params, config)
          .then(
            // eslint-disable-next-line no-return-assign
            response => {
              this.respuesta = response
              this.isSaving = false
              if (this.respuesta.status === 200) {
                this.$store.dispatch('showSnack', {
                  text: 'Actualizacion exitosa',
                  color: 'success',
                  timeout: 2500,
                })
                this.$router.push({ name: 'employee' })
              }
            },
          )
          .catch(e => {
            if (e.response) {
              this.mensajeSnack = e.response.data
            } else {
              this.mensajeSnack = 'Error de conexion'
            }
            this.$store.dispatch('showSnack', {
              text: this.mensajeSnack,
              color: 'error',
              timeout: 2500,
            })
            this.isSaving = false
          })
      }
    },
  },
}
</script>
